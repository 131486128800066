import React from 'react'
import Helmet from 'react-helmet'
import { useSelector } from 'react-redux'

import './all.sass'
import useSiteMetadata from './SiteMetadata'
const TemplateWrapper = ({ children, menu }) => {
    const { title, description, mainpitch } = useSiteMetadata()
    const b = useSelector(state => state.message, [])
    return (
        <div>
            <Helmet>
                <html lang="hu" />
                <title>{title}</title>
                <meta name="description" content={description} />
                <meta name="theme-color" content="#fff" />
                <meta name="keywords" content="jóga, yoga, Yoga lessons, Yogastunden, angol, német, spanyol, Nyelvtanfolyamok, Nyelvtanfolyam, Language courses, Sprachkurse, Nyelvórák, Nyelvóra, Language lessons, Sprachstunden, Fertőszentmiklós, Sopron,  " />
                <meta property="og:type" content="business.business" />
                <meta property="og:title" content={title} />
                <meta property="og:url" content="/" />
                <meta property="og:image" content="/img/landing.jpg" />
            </Helmet>

            <p>{mainpitch}</p>
            <div>{children}</div>
        </div>
    )
}
export default TemplateWrapper
